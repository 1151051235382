<template>
    <v-container>
        <v-tabs v-model="tab">
            <v-tab>
                Images of the Area
            </v-tab>
            <v-tab-item>
                <AreaImages />
            </v-tab-item>

            <v-tab>
                Movies of the Area
            </v-tab>
            <v-tab-item>
                <AreaMovies />
            </v-tab-item>

            <v-tab>
                Getting There
            </v-tab>
            <v-tab-item>
                <GettingThere />
            </v-tab-item>

            <v-tab>
                Kayak tracks
            </v-tab>
            <v-tab-item>
                <Kayak />
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>

 import AreaImages from './AreaImages';
 import AreaMovies from './AreaMovies';
 import GettingThere from './GettingThere';
 import Kayak from './Kayak';

 export default {
     name: 'Loch',
     components: {
         AreaImages,
         GettingThere,
         AreaMovies,
         Kayak
     }
 }

</script>
