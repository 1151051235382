<template>
    <v-card>
        <v-card-title>Movies of the area</v-card-title>
        <v-card-text>
            <span v-for="(movie, index) in areamovies" :key="index">
                <Movie :movie="movie">
                </Movie>
            </span>
        </v-card-text>
    </v-card>
</template>

<script>

 import Movie from './Movie';
 import { fetch_one } from "../utils.js";
 var data = { 'areamovies': []};
 export default {
     name: 'AreaMovies',

     data: function() {
         return data;
     },
     components: {Movie},
     mounted: async function() {
         var movie_list = await fetch_one("/area-movies.json");
         console.log("---------------------------");
         console.log(movie_list);
         if (movie_list) {
             data['areamovies'] = movie_list;
         }
     },
 };
</script>
