<template>
    <div>
        <h3 v-if="movie.title">{{movie.title}}</h3>
        <video-player :src="movie.movie" class="videoplayer" />
        <h4 v-if="movie.date"><em>Date: {{movie.date}}</em></h4>
        <p v-if="movie.about">{{movie.about}}</p>
    </div>
</template>

<script>
 import { videoPlayer } from 'vue-md-player'
 import 'vue-md-player/dist/vue-md-player.css'
 export default {
     components: {
         videoPlayer
     },
     props: ['movie']
 }
</script>
<style>
 .videoplayer {
     margin-top: .5em;
     margin-bottom: .5em;
 }
</style>
