<template>
  <v-app>
    <v-main>
      <Loch/>
    </v-main>
  </v-app>
</template>

<script>
 import Loch from './components/Loch';

 export default {
     name: 'LochLomond',

     components: {
         Loch,
     },

     data: () => ({
         //
     }),

     created() {
         document.title = "Loch Lomond";
     },
 };
</script>
