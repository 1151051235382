<template>
    <v-row >
        <v-col cols="6">
            <a :href="movie.movie">
                <v-img
                    v-if="movie.thumbnail"
                    max-height="200"
                    :src="movie.thumbnail" contain>
                </v-img>
                <h3 v-if="!movie.thumbnail" height="200">
                    (No preview available)
                </h3>
            </a>
        </v-col>
        <v-col cols="6">
            <h3 v-if="movie.title">{{movie.title}}</h3>
            <h4 v-if="movie.date"><em>Date: {{movie.date}}</em></h4>
            <p v-if="movie.about">{{movie.about}}</p>
        </v-col>
    </v-row>
</template>

<script>
 export default {
     name: 'Movie',
     props: ['movie']
 }
</script>
