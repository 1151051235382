<template>
    <v-card>
        <v-card-title>Images of the area</v-card-title>
        <v-card-text>
            <p>(click for a larger view)</p>
            <v-row>
                <!-- <v-col cols="4">
                     <a href="/images/now-bay.jpg"><v-img src="/images/now-bay.jpg" contain></v-img></a>
                     <p>Local view of the bay</p>

                     <v-img contain src="/images/bay-yesterday.jpg">
                     </v-img>
                     <p>Yesterday at 12:00</p>

                     <p>Recent timelapse videos:</p>
                     <ul>
                     <li v-for="(movie, index) in baymovies.slice(0,7)" :key="index">
                     <a :href="movie.movie">
                     {{movie.date}}
                     </a>
                     </li>
                     </ul>
                     </v-col> -->
                <v-col cols="4">
                    <a href="/images/now-mttam.jpg"><v-img src="/images/now-mttam.jpg" contain></v-img></a>
                    <p>Local view of Mt Tam</p>

                    <v-img contain src="/images/mttam-yesterday.jpg">
                    </v-img>
                    <p>Yesterday at 12:00</p>

                    <p>Recent timelapse videos:</p>
                    <ul>
                        <li v-for="(movie, index) in mttammovies.slice(0,7)" :key="index">
                            <a :href="movie.movie">
                                {{movie.date}}
                            </a>
                        </li>
                    </ul>
                </v-col>
                <v-col cols="4">
                    <a href="/images/now.png"><v-img src="/images/office-now.png" contain></v-img></a>
                    <p>Other view right now</p>

                    <v-img contain src="/images/office-yesterday.png">
                    </v-img>
                    <p>Yesterday at 12:00</p>

                    <p>Recent timelapse videos:</p>
                    <ul>
                        <li v-for="(movie, index) in movies.slice(0,7)" :key="index">
                            <a :href="movie.movie">
                                {{movie.date}}
                            </a>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

 import { fetch_one } from "../utils.js";
 var data = { 'movies': [],
              'baymovies': [],
              'mttammovies': []};
 export default {
     name: 'Loch',

     data: function() {
         return data;
     },
     mounted: async function() {
         var movie_list = await fetch_one("/office-movies.json");
         if (movie_list) {
             data['movies'] = movie_list;
         }

         movie_list = await fetch_one("/bay-movies.json");
         if (movie_list) {
             data['baymovies'] = movie_list;
         }

         movie_list = await fetch_one("/mttam-movies.json");
         if (movie_list) {
             data['mttammovies'] = movie_list;
         }
     },
 };
</script>
