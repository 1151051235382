<template>
    <v-card>
        <script type="application/javascript"
                src='https://api.tiles.mapbox.com/mapbox-gl-js/v0.54.0/mapbox-gl.js'></script>
        <v-card-title>Kayaking tracks</v-card-title>
        <v-card-text>
            <em>(red is the most recent track)</em>
            <div id="kayakmap" style="height: 500px;">
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
 import mapboxgl from 'mapbox-gl'
 import { fetch_one } from "../utils.js";

 console.log(mapboxgl);
 
 export default {
     name: 'Kayak',
     data: () => ({}),
     mounted() {
         let mapbox = document.createElement('script')
         mapbox.setAttribute('src', 'https://api.tiles.mapbox.com/mapbox-gl-js/v0.54.0/mapbox-gl.js')
         document.head.appendChild(mapbox)
         console.log("injected");
         this.maploaded();
     },
     methods: {
         'maploaded': async function() {
             this.map = new mapboxgl.Map({
                 accessToken:
         "pk.eyJ1IjoiaGFyZGFrZXIiLCJhIjoiY2x1bXptdjh1MWtiMjJpcDV2MTJtcnNsaiJ9.rpNfyg4bSXj_4aKcNc2dOw",
                 container: "kayakmap",
                 style: "mapbox://styles/mapbox/streets-v11",
                 minzoom: 5,
                 center: [-122.47351, 37.96694], // use initial data as default
                 zoom: 13,
                 // hash: true // sets url's hash to #zoom/lat/lng
             });

             var correctthis = this;

             this.map.on('load', async function() {
                 var track_list = await fetch_one("kayak_tracks.json");

                 var length = track_list.length

                 track_list.forEach(function(item, index) {
                     console.log("index:" + index)
                     correctthis.map.addSource('kayak' + index, {
                         type: 'geojson',
                         // Use a URL for the value for the `data` property.
                         data: item,
                     })

                     var color = 'blue';
                     var size = 2
                     if (index >= length-1) {
                         color = 'red';
                         size = 4
                     }
                     correctthis.map.addLayer({
                         'id': 'kayak-layer' + index,
                         'type': 'circle',
                         'source': 'kayak' + index,
                         'paint': {
                             'circle-radius': size,
                             'circle-stroke-width': 1,
                             'circle-color': color,
                             'circle-stroke-color': 'white'
                         }
                     });
                     console.log("adding layer")
                 })
             });
         },
     }
 }
</script>
<style>.
 #kayakmap {
     width: 100%;
     height: 500px;
     border-color: blue;
     border-width: 1px;
     border-style: solid;
 }
</style>
