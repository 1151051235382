export async function fetch_one(url) {
    try {
        console.log("fetching: " + url)
        var response =
            await fetch(url);
        var data = {'status': 'fail', 'message': {}};
        if (response.ok){
            data = await response.json();
        } else {
            console.log("    failed to fetch: " + response.status)
        }

        return data

    } catch (error) {
        console.log("failed to load " + url);
        console.log(error);
        console.log(response);
    }
}
