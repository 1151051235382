<template>
    <span>
        <v-card>
            <v-card-title>
                Getting There
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <ol>
                            <li>Take highway 101 North or South to the
                                <em>Central San Rafael</em> exit</li>
                            <li>Head east on second street for 2 miles which turns into Point San Pedro Rd</li>
                            <li>Turn right on Loch Lomond Dr (see the map to the right)</li>
                            <li>Turn left on Point San Pedro Rd</li>
                            <li>Park in one of the parking spots on this road</li>
                            <li>Contact Wes or Dawn when you arrive in the area</li>
                        </ol>
                    </v-col>
                    <v-col cols="6">
                        <a href="/images/parking.png"><v-img src="/images/parking.png" contain></v-img></a>
                        <p class="text-center">Parking information</p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-text>
                <Video :movie="movie">
                </Video>
            </v-card-text>
        </v-card>
    </span>
</template>    
<script>

 import Video from './Video';

 var movie =    { 
     "movie": "/images/getting-there/highway-101-southbound.mp4",
     "title": "Getting three from southbound HW-101",
     "about": "This movie shows the path from travelling south on HW-101 to our area.  There are a couple of turns that it's good to know where to be ahead of time.  Also note that it is sped up quite a bit at times, so don't drive at 400% speed like this video does in spots!",
     "thumbnail": "/images/getting-there/highway-101-southbound.png"
 };
 
 export default {
     name: 'GettingThere',
     data: () => ({"movie": movie}),
     components: {Video},
 }
</script>
